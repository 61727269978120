import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TextFieldGroup } from '~/admin/shared/forms/BasicForm';

const FollowPromptV2Fields = ({ props }) => {
  const intl = useIntl();
  return (
    <TextFieldGroup
      field="userInput"
      FormGroupProps={{ className: props.classes.emailFieldContainer }}
      InputProps={{ className: props.classes.emailField, 'data-cy': 'email_input' }}
      placeholder={intl.formatMessage({ defaultMessage: 'Email or Phone', id: 'users.email_or_phone' })}
    />
  );
};

FollowPromptV2Fields.propTypes = {
  props: PropTypes.object.isRequired,
};

export default FollowPromptV2Fields;
